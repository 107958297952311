import { observer } from 'mobx-react'
import { ChangeEvent, FC,  useEffect, useState } from 'react'
import { IDataObject } from '../../../core/types/source_service/IDataObject'
import { formatTableId } from '../../../core/utils/table_setup'
import { Box, Typography } from '@mui/material'
import checked from '../../../assets/checked.svg'
import { makeStyles } from '../../../core/utils/theme'
import useSettings from '../../../hooks/useSettings'
import Checkbox from '../../Misc/Checkbox'
import ConfirmationDialog from '../../Modals/ConfirmationDialog'
import { MAIN_SITE_URL, MAIN_SITE_URL_STAGING } from '../../../core/config/main'
import DialogContentText from '@mui/material/DialogContentText';
import { retrieveSourceParam } from '../../../core/utils/main'
import ReplayIcon from '@mui/icons-material/Replay';
import Loader from '../../Misc/Loader'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
  },
  descriptionContainer: {
    alignSelf: 'flex-end',
  },
  description: {
    fontWeight: 300,
  },
  tableFormContainer: {
    overflowY: 'auto',
  },
  loader: {
    margin: '0.5rem auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0px',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  formInput: {
    flexShrink: 0,
    width: 18,
    height: 18,
    WebkitAppearance: 'none',
  //borderRadius: 3,
    color: theme.palette.text.primary,

    '&:checked': {
      background: `url(${checked})`,
      backgroundPosition: 'center',
      border: 'none',
    },
  },
}))

interface IProps {
  // totalSteps: number
  // step: number
  // onStepChange: (step: number) => void
}
const StepSelectTables: FC<IProps> = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const source = retrieveSourceParam();
  const srcsMap = Object()

  useEffect(() => {
    const fetchDataAndSetMap = async () => {
      try {
        if (settings.fetchNeededforTables) {  // Condition to check if fetching is needed
          console.log('Fetching data sources as fetchNeededforTables flag is true');
          await settings.loadDataObjects(); // Wait for data objects to load
          console.log('Data sources are loaded, StepSelectTables');

          const size = Object.keys(srcsMap).length;
          console.log('bigquery settings.dataSources.length: ', settings.dataSources.length, ' settings.isLoading: ', settings.isLoading, ' srcsMap.length: ', size);
          
          if (settings.dataSources.length === 0 && settings.isLoading === false && size === 0) {
          
            // TODO: is there more reliable way to find out source?
            // special case for BigQuery
            if (source == 'bigquery' || source == 'redirect') {
              console.log('source: ', source);
              
              setOpenConfirmDialog(true); // Open the confirmation dialog
              return;
            }
          }
        }
      } catch (error) {
        console.error('Error loading data objects:', error);
      }
    };
  
    fetchDataAndSetMap(); // Execute the async function only if the condition is true
  }, [settings.fetchNeededforTables]); // Dependency only on the fetch flag

  const handleReload = () => {
    settings.resetDataObjects()
    settings
      .loadDataObjects()
      .then(() => {
        console.log('loaded tables')
      })
      .catch((error) => {
        console.log('error', error)
      })
  }


  settings.dataObjects.forEach((src: IDataObject) => {
    let src_type = null
    if (src.is_addon)
      src_type = `datacake: ${src.addon_id}`
    else
      src_type = src.source
    if (!srcsMap[src_type])
      srcsMap[src_type] = []
    srcsMap[src_type].push(src)
  })

  return (
    <Box className='flex-1 /*rounded*/ w-full h-[400px] lg:h-[600px] xl:h-[400px] p-4 border border-[#0A191C1F] relative z-1'>
      {settings.isLoading && <Loader className={classes.loader} />}
      <Box className='overflow-y-auto h-full z-1'>
        <Box className="h-full w-full relative XXX">
          <ConfirmationDialog
            open={openConfirmDialog}
            onClose={() => {
              setOpenConfirmDialog(false);
              window.location.href = (window.location.host.indexOf("app-") >= 0) ? MAIN_SITE_URL_STAGING : MAIN_SITE_URL
            }}
            onConfirm={() => {
              window.location.href = "/?openModal=2&source=csv";
            }}
            title="No BigQuery Tables Loaded"
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: 'white',
                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.3)',
                zIndex: 10 // You can set this to any number that works for your layering context
              }
            }}
          >
            <DialogContentText id="confirmation-dialog-description" style={{ color: 'black' }}>
              There are no accessible/queryable tables in your BigQuery account (
              <a href="https://cloud.google.com/bigquery/docs/control-access-to-resources-iam" target="_blank" rel="noopener noreferrer">
                learn more
              </a>
              ). Would you like to try uploading a CSV file?
            </DialogContentText>
          </ConfirmationDialog>

          <Box
            className={`${classes.tableFormContainer} relative h-full`}
            sx={[
              settings.isLoading && settings.dataObjects.length === 0
                ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }
                : {}, // for center loader
            ]}
          >
            {/* {settings.isLoading && <Loader className={classes.loader} />} */}
            {Object.keys(srcsMap).map((srcType: string, i: number) => {
              return (
                <div key={i}>
                  <Typography className='mb-1' variant="h6">{srcType}</Typography>
                  {srcsMap[srcType].map((src: IDataObject) => {
                    return (<TableForm key={src.table_full_id} dataObject={src} />)
                  })}
                </div>
              )
            })}
          </Box>
        </Box>
      </Box>
      <ReplayIcon className="absolute right-6 top-2 cursor-pointer z-100" onClick={handleReload} />
    </Box>

  )
})

export default StepSelectTables

interface ITableForm {
  dataObject: IDataObject
}
const TableForm: FC<ITableForm> = observer(({ dataObject }) => {
  const settings = useSettings()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    settings.toggleSelectedTable(dataObject.table_full_id, e.target.checked)
  }

  return (
    <Checkbox
      label={formatTableId(dataObject.table_full_id)}
      isSelected={dataObject.is_selected}
      handleChange={handleChange}
    />
  )
})

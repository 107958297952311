import { FC, MouseEvent, PropsWithChildren } from 'react'
import { makeStyles } from '../../core/utils/theme'

const useStyles = makeStyles()((theme) => ({
  root: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  //borderRadius: '0.25rem',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '0.75rem 2rem',
    textAlign: 'center',
    letterSpacing: '0.1rem',
    color: theme.palette.text.primary,
    textDecoration: 'none',

    '&:hover, &:focus, &:active': {
      color: theme.palette.text.disabled,
    },
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
    textDecoration: 'none',
    border: `1px solid ${theme.palette.text.disabled}`,

    '&:hover, &:focus, &:active': {
      color: theme.palette.text.disabled,
    },
  },
}))

interface IProps {
  href?: string
  className?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  disabled?: boolean
  [key: string]: any
}
const SecondaryButtonLink: FC<PropsWithChildren<IProps>> = ({
  children,
  href = '#',
  className,
  onClick,
  disabled = false,
  ...rest
}) => {
  const { classes, cx } = useStyles()

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      return
    }
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <a
      className={cx(classes.root, className, {
        [classes.disabled]: disabled,
      })}
      href={href}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </a>
  )
}

export default SecondaryButtonLink

import { Avatar, Box, Menu, MenuItem, Typography, alpha } from '@mui/material'
import {makeStyles} from '../../core/utils/theme'
import theme from '../../core/utils/theme'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LOGOUT_URL } from '../../core/config/main'
import DataSourcesModal from '../DataSources/DataSourcesModal'
import { colorObj } from '../../core/utils/helper'
import useUser from '../../hooks/useUser'
import useSettings from '../../hooks/useSettings'
import DataSourceManagerModal from '../DataSources/DataSourceManagerModal'
import useApp from '../../hooks/useApp'
import DescriptionsModal from '../Modals/DescriptionsModal'
import DatacakesModal from '../Datacakes/DatacakesModal'
import CreateDataRecipeModal from '../CakeBaking/CreateDataRecipeModal'

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  circle: {
    width: '40px',
    height: '40px',
    marginRight:'40px',
    marginTop:'6px',
    fontSize:'20px',
    [theme.breakpoints.down('md')]: {
      marginRight:'32px',
      marginTop:'13px',
      width: '36px',
      height: '36px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      width:'33px',
      height:'33px',
      marginRight:'4px',
      marginTop:'3px',
      fontSize: '20px'
    }
  }
}))

const AvatarMenu = () => {
  const navigator = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useUser()
  const app = useApp()
  const settings = useSettings()
  const userEmail = user.userEmail
  const userInitial = userEmail?.substring(0, 1) ?? 'X'
  const { classes } = useStyles()
  const [initialBackgroundColor, setInitialBackgroundColor] = useState('bg-slate-800');

  useEffect(() => {
    Object.keys(colorObj).forEach((key) => {
      if (key === userInitial.toLowerCase()) {
        console.log('Initial found', key);
        setInitialBackgroundColor(colorObj[key as keyof typeof colorObj])
      }
    })
  }, [userInitial])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (tabIndex: number | string) => {
    setAnchorEl(null);
    if (typeof (tabIndex) == 'number') {
      if (tabIndex === 2) {
        settings.setIsGuideTourModalOpen(true);
        navigator('/')
        return;
      }
      navigator(`/preferences?tab=${tabIndex}`);
    }
    else if (tabIndex == 'datasrcs')
      settings.setIsDataSourcesModalOpen(true)
    else if (tabIndex == 'datacakes')
      settings.setIsDatacakesModalOpen(true)
  };

  const handleLogout = () => {
    window.location.href = LOGOUT_URL;
  }

  return (
    <Box className={classes.root}>
      <span id='avatarMenu' onClick={handleClick}>
        <Avatar className={`${initialBackgroundColor} ${classes.circle} cursor-pointer text-white uppercase`}>{userInitial}</Avatar>
      </span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderWidth: 1,
            borderColor: 'black',
            background: 'white',
            marginTop: 1,
            width: 220,
            height: 300,
            '& .MuiList-root': {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            },
            '& .MuiMenuItem-root': {
              display: 'flex',
              flex: 1,
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
              },
              '&:active': {
                backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity,
                ),
              },
            },
          }
        }}
      >
        <MenuItem sx={{
          '&:focus': {
            backgroundColor: 'lightgray'
          },
          '&:active': {
            backgroundColor: 'transparent'
          }
        }}>
          <Box className='w-full h-[4rem] flex flex-col justify-center mb-3'>
            <Typography variant='h6'>Logged in as:</Typography>
            <div className="text-right">{userEmail}</div>
          </Box>
        </MenuItem>
        {/* <MenuItem onClick={() => handleClose(0)}>Profile</MenuItem> */}
        <MenuItem className='adminMenuItem' onClick={() => handleClose('datacakes')}>Your Datacakes</MenuItem>
        <MenuItem onClick={() => handleClose('datasrcs')}>Data Sources</MenuItem>
        {window.location.pathname == '/ask' && app.cakeId && <MenuItem className='' onClick={() => handleClose(2)}>Tour</MenuItem>}
        {/* <MenuItem onClick={() => handleClose(2)}>Users</MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <DescriptionsModal />
      <DatacakesModal />
      <CreateDataRecipeModal />
      <DataSourcesModal />
      <DataSourceManagerModal />
    </Box>
  )
}

export default AvatarMenu

import { Box, Input, Typography, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { ITable } from '../../../core/types/source_service/ITable'
import theme, { makeStyles } from '../../../core/utils/theme'
import useSettings from '../../../hooks/useSettings'
import checked from '../../../assets/checked.svg'
import Checkbox from '../../Misc/Checkbox'
import { APP_NAME } from '../../../core/config/main'
import Loader from '../../Misc/Loader'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: '1fr 70vh max-content',
    },
  },
  description: {
    fontWeight: 300,
    alignSelf: 'flex-end',
  },
  columnsRoot: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  selectAllColumnBtn: {
    marginBottom: '0.5rem',
    alignSelf: 'flex-end',
    color: theme.palette.text.primary,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.1rem 1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '0.25rem',
      alignItems: 'flex-start',
    },
  },
  tableName: {
    width: '100%',
    maxWidth: '13rem',
    overflowWrap: 'break-word',
    fontWeight: 500,
    textTransform: 'uppercase',
    background:'white'
  },
  tableInput: {
    width: '100%',
    wordWrap: 'break-word',
    padding: '4px 4px',
    border: `1px solid ${theme.palette.text.disabled}`,
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    color: '#222',
    background:'white'
  },
  columnsContainer: {
    marginTop: '0.5rem',
  },
  checkBoxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    wordBreak: 'break-all',
    fontWeight: '400',
  },
  checkBox: {
    minWidth: '20px',
    marginLeft: '0.125rem',
    width: '1rem',
    height: '1rem',
  //borderRadius: '0.25rem',
    color: theme.palette.text.primary,
    WebkitAppearance: 'none',
    border: `1px solid ${theme.palette.text.disabled}`,

    '&:checked': {
      background: `url(${checked})`,
      backgroundSize: '100% 100%',
      border: 'none',
    },
  },
  loaderContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingDescription: {
    textAlign: 'center',
    fontWeight: 300,
    fontStyle: 'italic',
    color: '#222',
  },

}))

interface IProps {
  // totalSteps: number
  // step: number
  // onStepChange: (step: number) => void
}

const StepDescribeColumns: FC<IProps> = observer(() => {
  const { classes } = useStyles()
  const [focusedId, setFocusedId] = useState<string | null>(null)

  const settings = useSettings()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {

    if (settings.fetchNeededforTableDetails) {
      settings
        .loadTableDetails()
        .then(() => {
          console.log('Loading table details')
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [settings, settings.fetchNeededforTableDetails, settings.cameBackFromLinks])

  const getTableDescription = (table: ITable) => {
    // Check local history first.
    const descriptionFromLocalHistory = settings.getTableDescriptionFromLocalHistory(table.id)
    if (descriptionFromLocalHistory !== null) {
      return descriptionFromLocalHistory
    }

    // Check database next.
    if (table.description !== null) {
      return table.description
    }

    // Check data from AI.
    const descriptionFromAi = settings.getTableDescriptionFromAi(table.id)
    if (descriptionFromAi) {
      return descriptionFromAi
    }

    return ''
  }
  const getColumnDescription = (table: ITable, column: ITableColumn) => {
    // Check local history first.
    const descriptionFromLocalHistory = settings.getTableColumnDescriptionFromLocalHistory(
      table.id,
      column.name,
    )
    if (descriptionFromLocalHistory !== null) {
      return descriptionFromLocalHistory
    }

    // Check database next.
    if (column.description !== null) {
      return column.description
    }

    // Check data from AI.
    const descriptionFromAi = settings.getTableColumnDescriptionFromAi(table.id, column.name)
    if (descriptionFromAi !== null) {
      return descriptionFromAi
    }

    return ''
  }

  return (
    <Box className={`ABCDE flex-1 /*rounded*/ w-full max-w-[90%] h-[400px] lg:h-[600px] xl:h-[400px] p-0 h-[35vh] border border-[#0A191C1F]`}>
    {settings.isLoading && <Loader className={classes.loader} />}
    {/* Guess Loader */}
    {settings.isLoadingAiDescriptions && (
      <Box className={classes.loaderContainer}>
        <Box className={classes.loadingDescription}>
          {APP_NAME + ' is generating guesses for missing descriptions...'}
        </Box>
        <Loader className={classes.loader} />
      </Box>
    )}
      <Box className={`overflow-y-auto ${(settings.isLoading || settings.isLoadingAiDescriptions) ? 'h-[75%]' : 'h-full'}`}>
        <Box className={`${classes.root} flex flex-col gap-1 `}>
          <Box
            className={`${classes.columnsRoot} mb-6`}
            sx={[
              settings.isLoading ? { justifyContent: 'center', alignContent: 'center' } : {}, // for center loader
            ]}
          >
            {/* Columns with descriptions */}
            {settings.sourceTables.length > 0 &&
              settings.sourceTables.map((table: ITable, index: number) => (
                <Box key={table.id}>
                  <Box className={`${classes.row} ${index > 0 && 'border-t-2 mt-2'} border-[#ddd] p-4 ${isMobile && 'py-3'}`}>
                    <Box className={'flex flex-row uppercase items-center w-full lg:w-4/12 pl-1'}>
                      <Checkbox
                        label={''}
                        isSelected={settings.areAllTableColumnChecked(table.id)}
                        handleChange={() => {
                          settings.toggleAllTableColumn(table.id)
                        }}
                      />
                      <Typography variant='h6' fontWeight={"800"}  textOverflow="ellipsis" className='break-all'>{table.name}</Typography>
                    </Box>
                    <Box className={'w-full lg:flex-1'}>
                      <Input
                        className={`${classes.tableInput} min-h-8`}
                        autoFocus={table.id === focusedId}
                        multiline={true}
                        maxRows={3}
                        onFocus={(e) => {
                          e.preventDefault()
                          const val = e.target.value
                          e.target.value = ''
                          e.target.value = val
                          setFocusedId(table.id)
                        }}
                        onBlur={(e) => {
                          e.preventDefault()
                          setFocusedId(null)
                        }}
                        sx={[
                          settings.isTableDescriptionGuessed(table.id) && {
                            fontStyle: 'italic !important',
                            color: () => '#222' + ' !important',
                          },
                          settings.isTableDescriptionEntered(table.id) && {
                            fontStyle: 'normal !important',
                            fontWeight: '600',
                            color: (theme) => theme.palette.text.primary + ' !important',
                          }
                        ]}
                        disableUnderline
                        defaultValue={getTableDescription(table)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          settings.updateTableDescription(table.id, e.target.value)
                        }}
                      />
                    </Box>
                    {/* DATA SOURCE ICON */}
                    {/* <Box>
                      <img src={getDataServiceIcon(table.source)} alt={table.source} width={24} height={24} />
                    </Box> */}
                  </Box>

                  <Box>
                    {table.columns &&
                      table.columns.length > 0 &&
                      table.columns.map((column: ITableColumn) => (
                        <Box key={column.name} className={classes.row}>
                          <Box className={'w-full lg:w-4/12 pt-2'}>
                            <Checkbox
                              label={column.name}
                              labelColor={'text-[#333]'}
                              isSelected={settings.isTableColumnEnabled(table.id, column.name)}
                              handleChange={() => {
                                settings.toggleTableColumn(table.id, column.name)
                              }}
                            />
                          </Box>
                          <Box className={'w-full lg:flex-1'}>
                            <Input
                              className={`${classes.tableInput} min-h-8`}
                              autoFocus={`${table.id}-${column.name}` === focusedId}
                              multiline={true}
                              onFocus={(e) => {
                                e.preventDefault()
                                const val = e.target.value
                                e.target.value = ''
                                e.target.value = val
                                setFocusedId(`${table.id}-${column.name}`)
                              }}
                              onBlur={(e) => {
                                e.preventDefault()
                                setFocusedId(null)
                              }}
                              sx={[
                                settings.isTableColumnDescriptionGuessed(table.id, column.name) && {
                                  fontStyle: 'italic',
                                  color: (theme) => theme.palette.text.disabled,
                                },
                                settings.isTableColumnDescriptionEntered(table.id, column.name) && {
                                  fontStyle: 'normal !important',
                                  fontWeight: '600',
                                  color: (theme) => theme.palette.text.primary + ' !important',
                                },
                              ]}
                              disableUnderline
                              defaultValue={getColumnDescription(table, column)}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                settings.updateTableColumnDescription(
                                  table.id,
                                  column.name,
                                  e.target.value,
                                )
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
})

export default StepDescribeColumns

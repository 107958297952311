import { Button as MuiButton } from '@mui/material'
import { observer } from 'mobx-react'
import { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import Button from '../Misc/Button'

// Icon
import ThumbsUp from '../../assets/Svgs/action/thumb_up_24px.svg';
import ThumbsUpFilled from '../../assets/Svgs/action/thumb_up_FILL_24px.svg';
import ThumbsDown from '../../assets/Svgs/action/thumb_down_24px.svg';
import ThumbsDownFilled from '../../assets/Svgs/action/thumb_down_FILL_24px.svg';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  feedbackIcon: {
    minWidth: '24px',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius: '50%',
    // border: `1px solid ${theme.palette.primary.main}`,
    padding: '0 0.5rem',
  },
  icon: {
    height: 24,
    width: 24,
  },
  tooltip: {
    zIndex: 10,
  },
  tellusmoreContainer: {
    margin: '0.25rem',
    display: 'flex',
    width: '100%',
    maxWidth: '300px',
    flexDirection: 'column',
  },
  tellusmoreText: {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  },
  tellusmoreInput: {
    marginBottom: '0.75rem',
  //borderRadius: '0.75rem',
    padding: '0.5rem',
    color: theme.palette.text.primary,
  },
}))

const FeedbackControl: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const [isFeedbackTooltipOpen, setIsFeedbackTooltipOpen] = useState<boolean>(false)

  const handleThumbsUp = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    app.flagAnswer(true)
  }
  const handleThumbsDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    app.flagAnswer(false, feedbackMessage.length > 0 ? feedbackMessage : undefined)
    setIsFeedbackTooltipOpen(false)
    setFeedbackMessage('')
  }
  const handleFeedbackMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    const newValue = e.target.value
    setFeedbackMessage(newValue)
  }
  const handleNegativeFeedbackTooltipStateChange = (value: boolean) => {
    setIsFeedbackTooltipOpen(value)
    if (value) {
      app.setIsPositiveFeedbackLeft(false)
    }
  }

  return (
    <div className={classes.root}>
      <MuiButton
        onClick={handleThumbsUp}
        className={classes.feedbackIcon}
      >
        <img src={app.isPositiveFeedbackLeft ? ThumbsUpFilled : ThumbsUp} className={classes.icon} />
      </MuiButton>
      <MuiButton
        data-tooltip-id="negative-feedback-tooltip"
        className={classes.feedbackIcon}
      >
        <img src={app.isNegativeFeedbackLeft ? ThumbsDownFilled : ThumbsDown} className={classes.icon} />
      </MuiButton>
      <Tooltip
        id="negative-feedback-tooltip"
        openOnClick={true}
        clickable={true}
        opacity={1}
        closeOnEsc={true}
        className={classes.tooltip}
        closeOnResize={true}
        closeOnScroll={true}
        isOpen={isFeedbackTooltipOpen}
        setIsOpen={handleNegativeFeedbackTooltipStateChange}
      >
        <div className={classes.tellusmoreContainer}>
          <div className={classes.tellusmoreText}>Please tell us more</div>
          <textarea
            className={classes.tellusmoreInput}
            rows={4}
            onChange={handleFeedbackMessageChange}
          ></textarea>
          <Button onClick={handleThumbsDown}>Submit</Button>
        </div>
      </Tooltip>
    </div>
  )
})

export default FeedbackControl

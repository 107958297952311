import { FC } from 'react'
interface IProps {
  className?: string
}

const Loader: FC<IProps> = ({ className }) => {
  return (
    <div className={className}>
      {/* <img src={loaderGif} alt={'Loader GIF'} style={{ width: '3.5rem' }} /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
        preserveAspectRatio="xMidYMid"
        viewBox="6 40 88 20"
      >
        <circle cx="84" cy="50" r="10" fill="#c87bb0">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="0.4629629629629629s"
            calcMode="spline"
            keyTimes="0;1"
            values="2;0"
            keySplines="0 0.5 0.5 1"
            begin="0s"
          />
          <animate
            attributeName="fill"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="discrete"
            keyTimes="0;0.25;0.5;0.75;1"
            values="#c87bb0;#27e3f9;#56c4e3;#939dc8;#c87bb0"
            begin="0s"
          />
        </circle>
        <circle cx="16" cy="50" r="10" fill="#c87bb0">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;2;2;2"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          />
        </circle>
        <circle cx="50" cy="50" r="10" fill="#939dc8">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;2;2;2"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.4629629629629629s"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.4629629629629629s"
          />
        </circle>
        <circle cx="84" cy="50" r="10" fill="#56c4e3">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;2;2;2"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.9259259259259258s"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.9259259259259258s"
          />
        </circle>
        <circle cx="16" cy="50" r="10" fill="#27e3f9">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;2;2;2"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1.3888888888888888s"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.8518518518518516s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1.3888888888888888s"
          />
        </circle>
      </svg>
    </div>
  )
}
export default Loader

export const colorObj = 
{
  a: "bg-red-600",
  b: "bg-green-300",
  c: "bg-emerald-400",
  d: "bg-teal-400",
  e: "bg-violet-400",
  f: "bg-orange-700",
  g: "bg-fuchsia-400",
  h: "bg-indigo-300",
  i: "bg-pink-400",
  j: "bg-fuchsia-800",
  k: "bg-rose-700",
  l: "bg-cyan-600",
  m: "bg-violet-800",
  n: "bg-green-800",
  o: "bg-purple-800	",
  p: "bg-teal-700",
  q: "bg-pink-800",
  r: "bg-yellow-600",
  s: "bg-amber-600",
  t: "bg-lime-600",
  u: "bg-blue-400",
  v: "springgreen",
  w: "bg-indigo-700",
  x: "bg-orange-300",
  y: "bg-sky-700",
  z: "bg-purple-400",
};
import { Box } from '@mui/material'
import logoHeader from '../../assets/datacakes-logo.svg'
import { MAIN_SITE_URL } from '../../core/config/main'
import { makeStyles } from '../../core/utils/theme'
import { FC } from 'react'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px'
    }
  },
  logo: {
    width: '200px',
    [theme.breakpoints.down('md')]: {
        width:'150px'
    }
  }
}))


const Logo: FC = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <a href={MAIN_SITE_URL} target="_blank">
        <img loading="lazy" src={logoHeader} alt="Datacakes Logo" className={classes.logo}  />
      </a>
    </Box>
  )
}

export default Logo
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useEffect, useState} from 'react'
import theme, { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import Loader from '../Misc/Loader'
import ActionLog from './ActionLog'
import AnswerSection from './AnswerSection'
import QuestionField from './QuestionField'
import { isEndUserView } from '../../core/utils/main'
import { IDashboardItem } from '../../core/types/code_service/IDashboardItem'
import loadinggif from '../../assets/loading.gif'
import { SampleQuestions } from './SampleQuestions2'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    padding:'0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
    justifyContent: 'flex-start',
  },
  picker: {
    color:'grey',
    '&:hover':{
      color:'black',
      cursor:'pointer'
    }
  },
  answerSectionWrapper: {
    width: '100%',
  },
  thinkingLoaderWrapper: {
    width: '100%',
  },
  thinkingLoader: {
    margin: '8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
  },
  thinkingLoaderText: {
    marginBottom: '8px',
    textAlign: 'center',
  },
  thinkingLoaderSpinner: {
    display: 'block',
    width: '10rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  questionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    height: '100%',
    marginTop: '1.2rem',
  },
  sampleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.5rem',
  },
  centralLabelContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '15%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1%',
    },
  },
  sampleQuestionLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2%',
  },
  indicatorsContainer: {
    display: 'none',
  },
  dropdownIndicator: {
    display: 'none',
  }
}))

const MainContent: FC = observer(() => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [showSaveToDashboard, setShowSaveToDashboard] = useState<boolean>(false)
  const isSaved = app.dashboardItems.map(x=>x.query_id).includes(app.queryId as string) || app.tempDashboardItems.map(x=>x.query_id).includes(app.queryId as string)
  // let shouldOpenSettingsModal: boolean = false

  useEffect(() => {

    console.log('app.cakeId', app.cakeId)

    const showS2DB = Boolean(app.answerText && app.queryId && (!isEndUserView() || app.cakeId=='nhanes_fd' || user.isInsider() || !user.isAuthenticated()))
    console.log("Setting showSaveToDashboard to", showS2DB)
    setShowSaveToDashboard(showS2DB)
    if (showS2DB) {
      setTimeout(()=>{
        settings.setTourStepIndex(2)
        settings.setTourRunning(true)  
      }, 400)
    }
  }, [app.answerText, app.queryId])

  const handleSaveToDashboard = (e: MouseEvent) => {
    if (isSaved)
      return
    e.preventDefault()
    e.stopPropagation()
    if (isEndUserView() && !user.isAuthenticated() && app.cakeId != 'nhanes_fd') {
      if (!settings.tourRunning)
        alert("You can save this answer to the Dashboard and view it there, but you must be logged in to create Dashboards that persist beyond this session.")
      app.addToTempDashboardItems({
        query_id: app.queryId || '', 
        answer: {
          answer: app.answerText,
          data: app.answerData, 
          chart_data: app.answerChartData, 
          chart_html: app.answerChartHtml||''
        },
        viz: app.answerChartHtml||''
      } as IDashboardItem)
      return
    }
    if (app.queryId)
      app.saveToDashboard(app.cakeId, app.queryId, e.target as HTMLElement)
  }

  const handleSaveToDashboardButtonLoaded = () => {
    console.log("saveToDashboardButtonLoaded")
  }

  const handleBake = () => {
    settings.hasDataSources
      ? settings.setIsCreateDataRecipeModalOpen(true)
      : settings.setIsDataSourcesModalOpen(true)
  }
  
  return (
    <>
      <Box
        className={`MAINCONTENT ${classes.root} flex-1 relative`}
      >
      <Box className="w-full">
        {app.cakeId &&
        <div style={{margin:'10px 0 5px', textAlign:'center'}}>
          <span className="mt-[5px] h-[100%] inline">
            <Typography className="inline-block" variant="h6">
              <span className={(user.isAuthenticated() && !isEndUserView()) ? 'cursor-pointer' : ''} onClick={()=>{if (user.isAuthenticated() && !isEndUserView()) settings.setIsDatacakesModalOpen(true)}}>{app.title ? app.title : app.cakeId}</span>
              {app.isDashboardLoading && 
                <img src={loadinggif} style={{display:'inline', height:'20px', marginLeft:'10px'}}/>
              }
            </Typography>
          </span>
        </div>
        }
        <Box className="h-[30px] flex justify-end align-middle">
          <Button variant='outlined' className="mt-2 cursor-pointer mr-4" id="dashboardShareButton" onClick={(e)=>{e.stopPropagation(); settings.setIsShareModalOpen(true)}}>Share Datacake</Button>
        </Box>
      </Box>
      <Box className={`ABCDE flex flex-col h-full mx-auto w-full ${isTablet && !isEndUserView() && 'lg:w-full'}`}>
          <QuestionField
            placeholder="Ask a question about your data..."
            autoFocus
            onFinish={(value: string) => {
              app.setInput(value)
              app.obtainAnswer()
            }}
            disabled={app.isThinking}
          />

          {!app.isThinking && !app.answerText && (
            <SampleQuestions />
          )}


          {!settings.isLoading && !isEndUserView() && !app.cakeId && (
            <Box className='min-h-40 flex items-center justify-center'>
              <Button className="w-[300px] bg-black px-4 py-1.5 text-white hover:bg-black" onClick={handleBake}>
                + Bake your first Datacake
              </Button>
            </Box>
          )}

          {app.isThinking && app.isActionLogOpenedInNewWindow && (
            <Box className={classes.thinkingLoader}>
              <Loader className={classes.thinkingLoaderSpinner} />
            </Box>
          )}
          <ActionLog />
          {showSaveToDashboard && 
          <div className="text-right text-xs text-black mb-2">
            <span id='saveToDashboard' style={{cursor:isSaved ? '' : 'pointer', border:"1px solid black", padding:'2px', margin:'2px 6px 50px 0'}} onClick={(e)=>handleSaveToDashboard(e)} onLoad={handleSaveToDashboardButtonLoaded}>{isSaved ? 'Saved' : 'Save to Dashboard'}</span>
          </div>
          }

          <Box
            className={`${classes.answerSectionWrapper} px-[1.25rem]`}
            sx={[
              !app.isThinking && {
                flexGrow: 1,
                overflowY: 'auto',
              },
              app.isActionLogOpenedInNewWindow && {
                height: { xs: 'auto', sm: '100%' },
              },
              app.isThinking && {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }
            ]}
          >
          <Box className={classes.thinkingLoaderWrapper}>
            {app.answerText && (
              <>
              <AnswerSection
                answerStr={app.answerText}
                answerData={app.answerData}
                answerChartData={app.answerChartData}
                answerChartHtml={app.answerChartHtml}
              />
              </>
            )}
          </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
})
export default MainContent

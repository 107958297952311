import { FC, useEffect, MouseEvent, useState } from 'react'
import PageLayout from '../Layouts/PageLayout'
import  theme, { makeStyles } from '../../core/utils/theme'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import { IDashboardItem } from '../../core/types/code_service/IDashboardItem'
import AnswerSection from '../AskCubie/AnswerSection'
import { useNavigate } from 'react-router-dom'
import { isEndUserView } from '../../core/utils/main'
import useUser from '../../hooks/useUser'
import loadinggif from '../../assets/loading.gif'
import ClearIcon from '@mui/icons-material/Clear'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import useSettings from '../../hooks/useSettings'
import HTMLChart from '../AskCubie/HtmlChart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { TourType } from '../../core/types/source_service/IGuideTour'
import LeftSidebar2 from '../LeftSidebar/LeftSidebar2'
import Tour, { dashboardHowToTourSteps } from '../Misc/Tour'

const useStyles = makeStyles()((theme) => ({
  root: {
    display:'flex',
    height:'100%'
  },
  slideOverlayContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  dashboardContainer: {
    width: '100%',
    padding: '0 20px',
    overflow: 'auto'
  },
  dashboardGridWrapper: {
    width: '650px',
    [theme.breakpoints.up(1650)]: {
      width: '1300px',
    },
    [theme.breakpoints.up(2330)]: {
      width: '1980px',
    },    
  },
  gridItemContainer: {
    margin:'20px',
    overflow:'hidden',
    position:'relative',
    width:'608px',
    height:'408px',
    border:'1px solid black'
  },
  newItemBox: {
    margin:'20px',
    width:'608px',
    height:'408px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    border:'1px solid #999',
    color: '#999',
    cursor: 'pointer',
    '& .hiddenChild': {
      // visibility:'hidden'
    },
    '&:hover': {
      color: 'black',
      border:'1px solid black',
      '& .hiddenChild': {
        // visibility:'visible'
      }
    }
  },
  newItemBoxText: {

  },
  chartContainer: {
    width:'600px',
    height:'382px',
    overflow:'hidden',
    zIndex:'0'
  },
  chartWrapper: {
    overflowY:'auto',
    position:'relative',
    scale:'67%',
    width:'150%',
    height:'150%',
    paddingY:'2px',
    left:'-150px',
    top:'-90px',
    zIndex: '1'
  },
  dashboardItemLowerControls: {
    position:'absolute',
    bottom:'0',
    left:'0',
    right:'0',
    backgroundOpacity:'100%',
    background:'#eee',
    height:'18px',
    width:'100%'
  }
}))

const timestr = (s: string) => {
  if (!s)
    return ''
  return s.slice(0,19).replace('T', '  ')
}

interface IProps {
  openOverlay: (items: IDashboardItem[], i: number) => void
}

const DashboardItems: FC<IProps> = observer(({openOverlay}) => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()
  const dashboardItems = app.dashboardItems.concat(app.tempDashboardItems)
  // const dashboardItems = app.dashboardItems.concat(app.dashboardItems).concat(app.dashboardItems).concat(app.dashboardItems).concat(app.dashboardItems).concat(app.tempDashboardItems)
  const navigate = useNavigate()

  useEffect(() => {
    if (!window.location.pathname.includes('dashboard'))
      navigate(isEndUserView() ? `/dashboard?cakeId=${app.cakeId}` : `/dashboard` )
  }, [app.cakeId])

  useEffect(() => {
    if (isEndUserView() && !user.isAuthenticated() && !settings.hasSeenDashboardHowTo)
      if (!(settings.tourType == TourType.DASHBOARD_HOW_TO))
        settings.startTour(TourType.DASHBOARD_HOW_TO, dashboardHowToTourSteps)
  }, [settings.tourType, settings.hasSeenDashboardHowTo])

  const rerunAll = async (e: MouseEvent<HTMLElement>) => {
    app.setIsDashboardLoading(true)
    const element = e.target as HTMLElement
    element.innerText = 'Running...'
    await app.runAllDashboardItems(app.cakeId, element)
    element.innerText = 'Done'
    setTimeout(() => {
      element.innerText = 'Re-run ALL analyses'
    }, 3000)
  }

  const rerun = async (e: MouseEvent<HTMLElement>, query_id: string) => {
    const element = e.target as HTMLElement
    element.innerText='Running...'  
    const r = await app.runDashboardItem(app.cakeId, query_id, element)
    console.log('r', r)
    app.loadDashboardItems(app.cakeId)
  }
  
  const handleDeleteDashboardItem = async (query_id: string) => {
    const r = await app.deleteDashboardItem(app.cakeId, query_id)
    console.log('r',r)
    app.loadDashboardItems(app.cakeId)
  }

  const newDashboardItemClick = () => {
    console.log("newDBItemClick")
    console.log(app.cakeId, user.isAuthenticated(), settings.hasSeenDashboardHowTo)
    app.prepareForQuestion(false)
    app.setInput('')
    if (isEndUserView() && !user.isAuthenticated() && settings.tourType == TourType.DASHBOARD_HOW_TO) {
      settings.setTourStepIndex(1)
    }
    navigate(isEndUserView() ? `/ask?cakeId=${app.cakeId}` : '/ask' )
  }

  const handleExpandItem = (i: number) => {
    openOverlay(dashboardItems, i)
  }
  
  return (
    <Box className="items-center overflow-y-auto" sx={{width:'100%'}}>
      {app.cakeId &&
      <div style={{margin:'10px 0 5px', textAlign:'center'}}>
        <span className="mt-[5px] h-[100%] inline">
          <Typography className="inline-block" variant="h6">
            <span className={(user.isAuthenticated() && !isEndUserView()) ? 'cursor-pointer' : ''} onClick={()=>{if (user.isAuthenticated() && !isEndUserView()) settings.setIsDatacakesModalOpen(true)}}>{app.title ? app.title : app.cakeId}</span>
            {app.isDashboardLoading && 
               <img src={loadinggif} style={{display:'inline', height:'20px', marginLeft:'10px'}}/>
            }
          </Typography>
        </span>
      </div>
      }
      {!app.isDashboardLoading && app.dashboardItems && app.dashboardItems.length > 0 &&
      <Box className="h-[30px] flex justify-end align-middle">
        <>
          <Button variant='outlined' className="mt-2 cursor-pointer mr-4" id="dashboardShareButton" onClick={(e)=>{e.stopPropagation(); settings.setIsShareModalOpen(true)}}>Share Dashboard</Button>
          <Button variant='outlined' className="mt-2 cursor-pointer" onClick={(e) => rerunAll(e)}>Re-run ALL analyses</Button>
        </>
        </Box>
      }
      <Box className="flex justify-center" id="dashboardContainer">
        <Box className={classes.dashboardGridWrapper} /*id='dashboardGridWrapper' onLoad={updateSize}*/>
          <Grid container className="FGHIJ" >
            {dashboardItems.map((item: IDashboardItem, i: number) => (
            <Box className="relative" key={item.query_id}>
              {(user.isInsider() || !isEndUserView() || app.cakeId == 'nhanes_fd') &&
              <ClearIcon
                className="absolute top-6 left-6 cursor-pointer opacity-10 hover:opacity-100 z-[2]"
                onClick={() => handleDeleteDashboardItem(item.query_id)}
              />
              }
              <OpenInFullIcon
                fontSize='small'
                className="absolute top-6 right-6 cursor-pointer opacity-10 hover:opacity-100 z-[2]"
                onClick={() => handleExpandItem(i)}
              />
              <Grid item id={ (settings.tourType == TourType.DASHBOARD_HOW_TO) && (i != dashboardItems.length-1) ? `dashboardItem_${i}` : 'dashboardViewNewItem'} className={classes.gridItemContainer}>
                {item.saved_at && 
                <Box className={classes.dashboardItemLowerControls}>
                  <Box className="text-black text-xs italic pb-1 pl-2 mr-1 float-left z-[2]" 
                  >
                    Last run {timestr(item.saved_at)}
                  </Box>
                  <Box onClick={(e) => rerun(e, item.query_id)} className="float-right cursor-pointer pr-2 pb-1 text-xs underline float-right z-[2]">
                    Re-run analysis
                  </Box>
                </Box>
                }
                <Box className={classes.chartContainer}>
                  <Box className={classes.chartWrapper}>
                    {item.answer?.chart_html ?  
                    <HTMLChart answerChartHtml={item.answer.chart_html} isDashboard={true}/>
                    :
                    <AnswerSection
                      answerStr={item.answer.answer} 
                      answerData={item.answer.data}
                      answerChartData={{}}
                      answerChartHtml={null}
                      isDashboard={true}/>
                    }
                  </Box>
                </Box>
              </Grid>
            </Box>
            ))}
            <Box className="relative">
              <Grid item id='dashboardAddNewItem' className={classes.newItemBox} onClick={newDashboardItemClick}>
                <div style={{visibility:'hidden'}}>New Dashboard Item</div>
                <div><AddIcon fontSize='large'/></div>
                <div className="hiddenChild">New Dashboard Item</div>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
})
interface ISlideOverlay {
  overlayItems: IDashboardItem[] | null
  currentItemIndex: number
  closeOverlay: ()=>void
  setCurrentItemIndex: (i:number)=>void
}

const SlideOverlay: FC<ISlideOverlay> = ({overlayItems, currentItemIndex, closeOverlay, setCurrentItemIndex}) => {
  const dbItem: IDashboardItem | null = overlayItems ? overlayItems[currentItemIndex] : null
  if (!dbItem)
    return null

  const nextIndex:number = overlayItems ? (currentItemIndex + 1) % overlayItems.length : 0
  const prevIndex:number = overlayItems ? (currentItemIndex - 1 + overlayItems.length) % overlayItems.length : 0

  return (
    <Box className="backdrop-blur-md absolute top-0 bottom-0 right-0 left-0 bg-white/80 z-[5] align-center">
      <Box className="h-full w-full flex ABCDE">
          <Box className="w-[90%] max-w-[1400px] aspect-video mx-auto my-auto bg-[#def] border border-black relative flex align-center">
          <CloseFullscreenIcon className="absolute top-2 right-2 cursor-pointer z-[2]" onClick={() => closeOverlay()} />
          {overlayItems && overlayItems.length > 1 && (
            <ArrowBackIosIcon className='absolute left-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(prevIndex)} />
          )}
          {overlayItems && overlayItems.length > 1 && (
            <ArrowForwardIosIcon className='absolute right-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(nextIndex)} />
          )}
            <Box className="w-[90%] max-w-[1200px] aspect-video mx-auto my-auto bg-[#def] border border-black">
              {dbItem.answer.chart_html && <HTMLChart answerChartHtml={dbItem.answer.chart_html} isDashboard={true} />}
            </Box>
          </Box>
      </Box>
    </Box>
  )
}

const Dashboard: FC = observer(() => {
  const { classes } = useStyles()
  const [overlayItems, setOverlayItems] = useState<IDashboardItem[]|null>(null)
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const openOverlay = (dbItems: IDashboardItem[], index: number) => {
    setOverlayItems(dbItems)
    setCurrentItemIndex(index)
  } 

  const closeOverlay = () => {
    setOverlayItems(null)
  }

  return (
    <PageLayout>
      <Box className={classes.root}>
        {!isMobile && <LeftSidebar2 />}
        <Box className={classes.slideOverlayContainer}>
          {overlayItems && <SlideOverlay overlayItems={overlayItems} currentItemIndex={currentItemIndex} closeOverlay={closeOverlay} setCurrentItemIndex={setCurrentItemIndex}/>}
        </Box>
        <Box className={classes.dashboardContainer}>
          <DashboardItems openOverlay={openOverlay} />
        </Box>
      </Box>
      <Tour/> 
    </PageLayout>
  )
})

export default Dashboard

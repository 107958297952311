import { FC } from 'react'
import { observer } from 'mobx-react'
import useSettings from '../../hooks/useSettings'
import Joyride, {ACTIONS, EVENTS, CallBackProps} from 'react-joyride'
import { TourType } from '../../core/types/source_service/IGuideTour'
import { Typography } from '@mui/material'

export const dashboardHowToTourSteps = [
  {
    target: '#dashboardAddNewItem',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        1. Creating a dashboard is easy! Click here to explore and visualize the insights hidden in your data.
      </Typography>
    ),
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '#runSampleQuestion',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        2. Cubie, our AI Data Analyst, helps you explore and visualize your data. Let's start with a sample question.
      </Typography>
    ),
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '#saveToDashboard',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        3. Once Cubie generates an answer and visualization, you can save it to the Dashboard with a single click.
      </Typography>
    ),
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '#dashboardButton',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        4. Go see your new item on the Dashboard.
      </Typography>
    ),
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '#dashboardViewNewItem',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        5. The visualization you just saved is here!
      </Typography>
    ),
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '#dashboardShareButton',
    title: "Dashboard Creation",
    content: (
      <Typography variant='body1' textAlign={'left'}>
        6. Sharing dashboards is easy, too!
      </Typography>
    ),
    placement: 'right',
    disableBeacon: true,
  }
]

const Tour: FC = observer(() => {
  const settings = useSettings()

    const handleDashboardTourCallBack = (data: CallBackProps) => {
      const { action, index, origin, status, type } = data

      console.log(settings.tourType)
      console.log('action, index, origin, status, type', action, index, origin, status, type)

      if (settings.tourType == TourType.DASHBOARD_HOW_TO) {
        if (action==ACTIONS.NEXT && type==EVENTS.STEP_AFTER) {
          if (settings.tourStepIndex == 0) {
            const elt = document.getElementById('dashboardAddNewItem')
            if (elt)
              elt.click()
            settings.setTourStepIndex(1)
            // settings.setTourRunning(false)
          } else if (settings.tourStepIndex == 1) {
            const elt = document.getElementById('runSampleQuestion')
            if (elt)
              elt.click()
            // settings.setTourStepIndex(2)
            settings.setTourRunning(false) // Have to wait for Save to Dashboard button to appear...
          } else if (settings.tourStepIndex == 2) {
            const elt = document.getElementById('saveToDashboard')
            if (elt)
              elt.click()
            settings.setTourStepIndex(3)
            // settings.setTourRunning(false)
          } else if (settings.tourStepIndex == 3) {
            const elt = document.getElementById('dashboardButton')
            if (elt)
              elt.click()
            settings.setTourStepIndex(4)
            // settings.setTourRunning(false)
          } else if (settings.tourStepIndex == 4) {
            const elt = document.getElementById('dashboardViewNewItem')
            if (elt)
              elt.click()
            settings.setTourStepIndex(5)
            // settings.setTourRunning(false)
          } else if (settings.tourStepIndex == 5) {
            const elt = document.getElementById('dashboardShareButton')
            if (elt)
              elt.click()
            settings.setTourRunning(false)
            settings.setTourDetails(TourType.NONE, [])
            settings.setTourStepIndex(0)
            settings.setHasSeenDashboardHowTo(true)
          }
        } else if (action === ACTIONS.CLOSE) {
          settings.setTourDetails(TourType.NONE, [])
          settings.setHasSeenDashboardHowTo(true)
          settings.setTourRunning(false)
        }
      }
    }

  return (
    <Joyride
      run={settings.tourRunning}
      steps={settings.tourSteps}
      stepIndex={settings.tourStepIndex}
      showProgress
      // showSkipButton
      hideBackButton
      continuous
      scrollToFirstStep
      // hideCloseButton
      disableCloseOnEsc
      disableOverlayClose
      scrollOffset={300}
      callback={handleDashboardTourCallBack}
      styles={{
      options: {
          primaryColor: 'black',
          overlayColor: 'rgba(0, 0, 0, 0.35)',
          width: 292,
          zIndex: 1500
      },
      spotlight: {
          zIndex: 1500,
          position: 'absolute',
      },
      overlay: {
          zIndex: 1500,
      }
      }}
  />
  )
})

export default Tour
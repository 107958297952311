import { FC } from 'react'
import MainContent from './MainContent'
import theme, { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import { observer } from 'mobx-react'
import { useMediaQuery } from '@mui/material'
import Tour from '../Misc/Tour';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexGrow:'1',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    paddingBottom: '0.5rem',
    flexWrap: 'nowrap',
    transition: 'all 0.3s ease-in-out',
  },
}))

const CubieAssistant: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <div className={classes.root}>

      <MainContent />
      {settings.sampleQuestions.length > 1 && !isSmallScreen && <Tour />}
    </div>
  )
});

export default CubieAssistant

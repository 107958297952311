import { Box, Input, Typography } from '@mui/material'
// import { observer } from 'mobx-react'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { APP_NAME } from '../../core/config/main'
// import { warning } from '../../core/services/alerts'
import { ITable } from '../../core/types/source_service/ITable'
import { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import Loader from './Loader'

import checked from '../../assets/checked.svg'
import ButtonLink from './ButtonLink'
import { observer } from 'mobx-react'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '0px',
    display: 'grid',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: '1fr 70vh max-content',
    },
  },
  description: {
    fontWeight: 300,
    alignSelf: 'flex-end',
  },
  columnsRoot: {
    marginBottom: '1.5rem',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 1rem',
    background: theme.palette.background.paper,
    overflowY: 'auto',
    border: `1px solid ${theme.palette.primary.main}`,
    height: '49.5vh',
  },
  loaderContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  loadingDescription: {
    textAlign: 'center',
    fontWeight: 300,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
  },
  selectAllColumnBtn: {
    marginBottom: '0.5rem',
    alignSelf: 'flex-end',
    color: theme.palette.text.primary,
  },
  sourceTableContainer: {
    paddingBottom: '1.5rem',
    paddingRight: '0.75rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.25rem 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '0.25rem',
      alignItems: 'flex-start',
    },
  },
  tableName: {
    width: '100%',
    maxWidth: '13rem',
    overflowWrap: 'break-word',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  tableInputContainer: {
    width: '100%',
  },
  tableInput: {
    width: '100%',
    wordWrap: 'break-word',
  //borderRadius: '0.125rem',
    border: `1px solid #AAA`,
    background: 'transparent',
    padding: '0.5rem 0.75rem',
    outline: 'none',

    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  columnsContainer: {
    marginTop: '0.5rem',
  },
  rowMain: {
    width: '100%',
    maxWidth: '13rem',
  },
  checkBoxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    wordBreak: 'break-all',
    fontWeight: '400',
  },
  checkBox: {
    minWidth: '20px',
    marginLeft: '0.125rem',
    width: '1rem',
    height: '1rem',
  //borderRadius: '0.25rem',
    color: theme.palette.text.primary,
    WebkitAppearance: 'none',
    border: `1px solid ${theme.palette.text.disabled}`,

    '&:checked': {
      background: `url(${checked})`,
      backgroundSize: '100% 100%',
      border: 'none',
    },
  },
}))

const ColumnDescriptions: FC = observer(() => {
  const { classes } = useStyles()
  const [focusedId, setFocusedId] = useState<string | null>(null)
  const settings = useSettings()

  useEffect(() => {
    async function fetchDescriptions() {
      console.log("TCD modal fetching descriptions")
      const r = await settings.loadTableColumnDescriptions()
      console.log('r', r)
      console.log("TCD modal fetched descriptions")
    }
    
    if (settings.tcDescriptions.length == 0) 
      fetchDescriptions()
  }, [settings.tcDescriptions])

  const handleSubmit = () => {
    settings.saveTableColumnDescriptions(settings.tcDescriptions as [])
    console.log(settings.tcDescriptions)
  }

  return (
    <Box className={`${classes.root} flex flex-col gap-1`}>
      <Typography variant="body1" className="font-300 mb-2">
        Edit descriptions of your tables and columns. This is very important&mdash;the more complete the description
        the better {APP_NAME} will understand how the tables and columns relate to each other and how to analyze the data.
      </Typography>

      <Box
        className={`${classes.columnsRoot} mb-6 min-h-[286px]`}
        sx={[
          settings.isLoading ? { justifyContent: 'center', alignContent: 'center' } : {}, // for center loader
        ]}
      >
        {/* Center Loader for all columns */}
        {settings.isLoading && <Loader className={classes.loader} />}

        {/* Select All Columns
        {settings.sourceTables.length > 0 && (
          <Button onClick={selectAllColumns} className="flex-end mb-2 text-gray-800">
            Select All Columns
          </Button>
        )} */}

        {/* Columns with descriptions */}
        { Object.values(settings.tcDescriptions).map((table: ITable) => (
            <Box key={table.id} className={classes.sourceTableContainer}>
              <Box className={classes.row}>
                <Box className={classes.tableName}>{table.name}</Box>
                <Box className={classes.tableInputContainer}>
                  <Input
                    className={classes.tableInput}
                    autoFocus={table.id === focusedId}
                    multiline={true}
                    onFocus={(e) => {
                      e.preventDefault()
                      const val = e.target.value
                      e.target.value = ''
                      e.target.value = val
                      setFocusedId(table.id)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      setFocusedId(null)
                    }}
                    sx={[
                      settings.isTableDescriptionGuessed(table.id) && {
                        fontStyle: 'italic !important',
                        color: () => '#999' + ' !important',
                      },
                      settings.isTableDescriptionEntered(table.id) && {
                        fontStyle: 'normal !important',
                        fontWeight: '600',
                        color: (theme) => theme.palette.text.primary + ' !important',
                      },
                    ]}
                    disableUnderline
                    defaultValue={table.description}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      settings.editTableDescription(table.id, e.target.value)
                    }}
                  />
                </Box>
              </Box>

              <Box className="mt-2">
                {table.columns &&
                  table.columns.length > 0 &&
                  table.columns.map((column: ITableColumn) => (
                    <Box key={column.name} className={classes.row}>
                      <Box className={classes.rowMain}>
                        <label className={classes.checkBoxLabel}>
                          {/* <input
                            checked={settings.isTableColumnEnabled(table.id, column.name)}
                            type="checkbox"
                            className={classes.checkBox}
                            onChange={() => {
                              settings.toggleTableColumn(table.id, column.name)
                            }}
                          /> */}
                          {column.name}
                        </label>
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Input
                          className={classes.tableInput}
                          autoFocus={`${table.id}-${column.name}` === focusedId}
                          multiline={true}
                          onFocus={(e) => {
                            e.preventDefault()
                            const val = e.target.value
                            e.target.value = ''
                            e.target.value = val
                            setFocusedId(`${table.id}-${column.name}`)
                          }}
                          onBlur={(e) => {
                            e.preventDefault()
                            setFocusedId(null)
                          }}
                          sx={[
                            settings.isTableColumnDescriptionGuessed(table.id, column.name) && {
                              fontStyle: 'italic',
                              color: (theme) => theme.palette.text.disabled,
                            },
                            settings.isTableColumnDescriptionEntered(table.id, column.name) && {
                              fontStyle: 'normal !important',
                              fontWeight: '600',
                              color: (theme) => theme.palette.text.primary + ' !important',
                            },
                          ]}
                          disableUnderline
                          defaultValue={column.description}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            settings.editColumnDescription(table.id, column.name, e.target.value)
                          }}      
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </Box>
      <Box className="justify-end text-right">
        <ButtonLink variant='enclosed' onClick={handleSubmit}>Submit</ButtonLink>
      </Box>
    </Box>
  )
})

export default ColumnDescriptions

import { createBrowserRouter } from 'react-router-dom'
import AskCubie2 from '../../components/AskCubie/AskCubie2'
import { APP_ROOT_PATH } from '../config/main'
import Dashboard2 from '../../components/Dashboard/Dashboard2'
import DataMarket from '../../components/DataMarket/DataMarket'

const mainRouter = createBrowserRouter(
  [
    {
      path: '/',
      element: <Dashboard2 />,
    },
    {
      path: '/ask',
      element: <AskCubie2 />,
    },
    {
      path: '/dashboard',
      element: <Dashboard2 />,
    },
    {
      path: '/datamarket',
      element: <DataMarket />
    }
  ],
  { basename: APP_ROOT_PATH },
)

export default mainRouter

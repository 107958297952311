import { sanitize } from 'isomorphic-dompurify'
import { FC, useState } from 'react'
import { IChartData } from '../../core/types/code_service/IChartData'
import AnswerChartModal from '../Modals/AnswerChartModal'
import AnswerChart from './AnswerChart'
import AnswerTable from './AnswerTable'
import FeedbackControl from './FeedbackControl'
import { makeStyles } from '../../core/utils/theme'
import HTMLChart from './HtmlChart'
import { Box } from '@mui/material'
import { useRef } from 'react'
import { observer } from 'mobx-react'

// Icon
// import ViewIcon from '../../../assets/Svgs/action/view_24 px.svg';
// import SplitScreenIcon from '../../../assets/Svgs/action/splitscreen_add_24_px.svg';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    marginBottom: '1rem',
    width: '100%',
    flexGrow: 1,
    fontFamily: 'Inconsolata, monospace',
    maxHeight: '0vh',
  },
  answerText: {
    textAlign: 'left',
    fontSize: '1.2rem',
  },
  feedbackControl: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1.5rem',
    marginTop: "10px",
    marginBottom: "10px",

    '& > span': {
      fontSize: '1.2rem',
      color: theme.palette.text.primary,
    },
  },
  buttonContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    alignSelf: 'center',
    textTransform: 'uppercase',
    padding: '0.5rem 1.5rem',
  },
  tableContainer: {
    display: 'block',
    maxWidth: '100%',
  },
  table: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 500,
  },
  icon: {
    width: 24,
    height: 24,
  }
}))

interface IProps {
  answerStr: string
  answerData: string[][]
  answerChartData: IChartData
  answerChartHtml: string | null
  isDashboard?: boolean
}

enum ModalType {
  CHART,
  DATA
}

const AnswerSection: FC<IProps> = observer(({ answerStr, answerData, answerChartData, answerChartHtml, isDashboard = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClick = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const { classes, cx } = useStyles()
  const ansDiv = useRef<HTMLDivElement>(null)
  const [modalType] = useState<ModalType>(ModalType.CHART);

  // Get reference to settings store
  const getContent = () => {
    if (modalType === ModalType.CHART) {
      if (answerChartHtml) return <HTMLChart answerChartHtml={answerChartHtml} isDashboard={isDashboard} />
      else return <AnswerChart fullWidth={true} data={answerChartData} onClick={handleClick} isDashboard={isDashboard}/>
    }

    if (modalType === ModalType.DATA) {
      return <AnswerTable containerStyle={classes.tableContainer} tableStyle={classes.table} data={answerData} />
    }
  }

  const newAnswer = (!answerChartData && answerStr.includes(answerData.toString())) ? answerStr.replace(answerData.toString(), `<b>${answerData.toString()}</b>`) : answerStr
  
  return (
    <div className={classes.root}>
      {!answerChartHtml &&
      <Box
        ref={ansDiv}
        dangerouslySetInnerHTML={{
          __html: sanitize(newAnswer.replace('\n', '<br>').replace('<code>', '<pre><code>').replace('</code>', '</code></pre>')),
        }}
        className={`${cx(classes.answerText, 'answerStr')} ${isDashboard ? 'mt-5 px-5' : ''}` }
      />}

      {answerChartHtml ? (
        <HTMLChart answerChartHtml={answerChartHtml} isDashboard={isDashboard} />
      ) : answerChartData ? (
        <AnswerChart
          data={answerChartData}
          onClick={handleClick}
          isDashboard={isDashboard}
          />
      ) : <div></div>
      }

      {isDashboard && 
      <Box sx={{height:'50px'}}></Box>
      }
      <AnswerTable data={answerData} />

      <AnswerChartModal open={isOpen} onClose={handleClose} title="Answer">
        {getContent()}
      </AnswerChartModal>

      {!isDashboard &&
        <Box className="flex flex-row items-center my-3">
          <Box className={classes.feedbackControl}>
            <span>How did I do?</span>
            <FeedbackControl />
          </Box>
        </Box>
      }
    </div>
  )
})

export default AnswerSection

import { IMktDatasetInfo } from "../../core/types/source_service/IMktDatasetInfo"

export const getFakeCakeMetadatas = ():IMktDatasetInfo[] => {
  return [
    {
      cakeId:'nh',
      title: 'NH Places of Interest',
      description: 'NH places of interest w/ addresses, category information, etc.',
      created_on:"2024-02-01",
      chef:"SF Inc"
    },
    {
      cakeId: "campaignfinance",
      title:"US FEC Campaign Finance Data 2020-2024",
      description:"Campaign finance reporting data for all candidates for federal office.",
      created_on:"2024-04-24",
      chef:"FEC"
    },
    {
      cakeId: "censusdata",
      title:"Census Data - State Demographics",
      description:"State-by-state demographic data from the 2020 U.S. Census",
      created_on:"2024-04-24",
      chef:"US Census Bureau"
    },
    {
      cakeId: "bqdemo",
      title:"SMB Business Activity",
      description:"Business activity data on small- and medium-sized business",
      created_on:"2024-04-24",
      chef:"SMB Inc"
    },
    {
      cakeId: "scraped",
      title:"AirBnB dataset",
      description:"AirBnB short-term rental listings data",
      created_on:"2024-04-24",
      chef:"AirBnB"
    },
  ]
}

